import React from 'react';
import styles from './styles';

function About() {
  return (
    <div style={styles.card}>
      <h1 style={styles.title}>About ai_budapest</h1>
      <p style={styles.subtitle}>
        ai_budapest is a non-profit initiative dedicated to organizing the Hungarian AI community, providing information, inspiration, and engagement. Our weekly newsletter, available at <a href="https://aibudapest.substack.com" style={styles.navLink}>AI Budapest Substack</a>, curates and shares the most important news related to AI.
      </p>
      <h2 style={styles.subtitle}>Projects</h2>
      <ul>
        <li style={styles.listItem}>
          <strong>Hungarian AI Landscape (2024)</strong>: In 2024, AI Budapest created a comprehensive open-source database called the <a href="https://ai-budapest.notion.site/60c633aef7734f7bbe3caac798d72103?v=23eced947fc84ec6b4b0e87497bcbed8" style={styles.navLink}>Hungarian AI Landscape</a>. This database is continuously updated to provide a transparent overview of AI capabilities and potential in Hungary.
        </li>
        <li style={styles.listItem}>
          <strong>AI Maturity Research (Q3-Q4 2024)</strong>: AI Budapest will conduct extensive quantitative and qualitative research on AI maturity and awareness in Hungary during the third and fourth quarters of 2024.
        </li>
      </ul>
    </div>
  );
}

export default About;
