import React, { useState } from 'react';
import styles from './styles';

const Button = () => {
  const [hover, setHover] = useState(false);

  return (
    <a
      href="https://aibudapest.substack.com"
      style={hover ? { ...styles.button, ...styles.buttonHover } : styles.button}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      Subscribe to our Newsletter
    </a>
  );
};

export default Button;
