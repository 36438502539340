const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    backgroundColor: '#fff',
    color: '#333',
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
  },
  nav: {
    width: '100%',
    backgroundColor: '#fff',
    borderBottom: '1px solid #e0e0e0',
    padding: '10px 20px',
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '40px',
  },
  navList: {
    display: 'flex',
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
  navItem: {
    margin: '0 15px',
  },
  navLink: {
    color: '#333',
    textDecoration: 'none',
    fontSize: '1em',
    fontWeight: '500',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '40px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    width: '100%',
    maxWidth: '800px',
    textAlign: 'center',
  },
  title: {
    fontSize: '2em',
    fontWeight: '700',
    marginBottom: '0.5em',
    color: '#333',
  },
  subtitle: {
    fontSize: '1em',
    marginBottom: '1.5em',
    color: '#666',
    lineHeight: '1.5',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1.5em',
  },
  button: {
    padding: '10px 20px',
    fontSize: '1em',
    color: '#fff',
    backgroundColor: '#0070f3',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    textDecoration: 'none',
    transition: 'background-color 0.2s ease',
  },
  buttonHover: {
    backgroundColor: '#005bb5',
  },
  listItem: {
    textAlign: 'left',
    marginBottom: '10px',
    color: '#333',
    lineHeight: '1.6',
  },
};

export default styles;
