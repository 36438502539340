import React from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import styles from './styles';
import About from './About';
import Button from './Button';

function App() {
  return (
    <Router>
      <div style={styles.container}>
        <nav style={styles.nav}>
          <ul style={styles.navList}>
            <li style={styles.navItem}><Link to="/" style={styles.navLink}>Home</Link></li>
            <li style={styles.navItem}><a href="https://aibudapest.substack.com/publish/home" style={styles.navLink}>Blog</a></li>
            <li style={styles.navItem}><a href="https://ai-budapest.notion.site" style={styles.navLink}>Hungarian AI Landscape</a></li>
            <li style={styles.navItem}><Link to="/about" style={styles.navLink}>About</Link></li>
          </ul>
        </nav>
        <Switch>
          <Route exact path="/">
            <div style={styles.card}>
              <h1 style={styles.title}>ai_budapest</h1>
              <p style={styles.subtitle}>
                AI Budapest aims to explore the possibilities of AI in collaboration with international communities and support the local community through various research and professional events. Join us and be part of the future technological developments!
              </p>
              <div style={styles.buttonContainer}>
                <Button />
              </div>
            </div>
          </Route>
          <Route path="/about" component={About} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
